import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../styles/navigationbar.css'

function NavigationBar() {
    return (
        <Navbar bg="dark" variant="dark">
            <div className="menu-container">
                <Navbar.Brand href="#home">GamifiedLife</Navbar.Brand>
                <Nav className="me-auto my-2 my-lg-0">
                    <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#features">Features</Nav.Link>
                    <Nav.Link href="#pricing">Pricing</Nav.Link>
                </Nav>
                <Nav className="mr-auto">
                    <Nav.Link href="#deets">Login</Nav.Link>
                    <Nav.Link href="#memes">Sign Up</Nav.Link>
                </Nav>
            </div>
      </Navbar>
    );
  }
  
  export default NavigationBar;