import React from 'react';
import '../styles/statgraph.css'
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
  } from 'chart.js';

  import { Radar } from 'react-chartjs-2';
  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );
  
  export const data = {
    labels: ['str', 'int', 'con', 'wis', 'char', 'dex'],
    datasets: [
      {
        label: 'Statistics',
        data: [5, 9, 22, 17, 13, 14],
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderColor: 'rgb(255, 255, 255)'
      }
    ],
  };
  export const options= {
    scales: {
      r: {
        angleLines:{
            color:'white'
        },
        grid: {
            color: 'white'
        },
        ticks: {
            color: 'white',
            showLabelBackdrop:false,
            font: {
                size: 15
            }
        },
        pointLabels: {
          color: 'white',
          font: {
            size: 20,
            style: 'italic'
          }
        }
      }
    }
}
  
function StatGraph() {
    return (
        <div className="stat-graph-container">
            <h1>Stats</h1>
            <Radar data={data} options={options}/>
        </div>
    
    );
  }

export default StatGraph