import logo from './logo.svg';
import './App.css';
import StatGraph from './components/statgraph'
import SkillsList from './components/skillslist'
import AchievementsList from './components/achievementslist'
import NavigationBar from './components/navigationbar'
import TaskList from './components/tasklist'

function App() {
  return (
    <div className="App">
      <NavigationBar />
      <div className="column-container">        
        <div className="stats-skills-ach-columm">
          <StatGraph/>
          <div class="line-3"/>
          <SkillsList/>
        </div>
        <div className="v1"/>
        <div className="tasks-column">
          <TaskList/>
        </div>
      </div>
    </div>
  );
}

export default App;
