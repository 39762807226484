import '../styles/tasklist.css'

function TaskList(){
    return(
        <div className="task-list-container">
            <h1>Tasks</h1>
            <div class="task-underline"/>
            <div class="task-list">
                
            </div>
        </div>
    );
}

export default TaskList