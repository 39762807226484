import ProgressBar from 'react-bootstrap/ProgressBar';
import '../styles/skill.css'
function Skill(props){
    return(
        <div data-augmented-ui="tl-clip br-clip bl-clip tr-clip border" class="skill-reticle">
            <div className="skill-item">
                <strong><p>{props.skill_name}: {props.skill_level}</p></strong>
                <ProgressBar variant="success" now={props.progress*100} />;
            </div>
        </div>
        
    )
}

export default Skill