import '../styles/skillslist.css'
import Skill from './skill'
import ListGroup from 'react-bootstrap/ListGroup';
function SkillsList(){
    const temp_skills={
        "skill-name-1": {
            "description":"description",
            "level":"master",
            "progress":0.75
        },
        "skill-name-2": {
            "description":"description",
            "level":"master",
            "progress":0.75
        },
        "skill-name-3": {
            "description":"description",
            "level":"master",
            "progress":0.75
        },
        "skill-name-4": {
            "description":"description",
            "level":"master",
            "progress":0.75
        },
        "skill-name-5": {
            "description":"description",
            "level":"master",
            "progress":0.75
        },
        "skill-name-6": {
            "description":"description",
            "level":"master",
            "progress":0.75
        },
        "skill-name-7": {
            "description":"description",
            "level":"master",
            "progress":0.75
        },
        "skill-name-8": {
            "description":"description",
            "level":"master",
            "progress":0.75
        },
        "skill-name-9": {
            "description":"description",
            "level":"master",
            "progress":0.75
        },
        "skill-name-10": {
            "description":"description",
            "level":"master",
            "progress":0.75
        },
        "skill-name-11": {
            "description":"description",
            "level":"master",
            "progress":0.75
        },
        "skill-name-12": {
            "description":"description",
            "level":"master",
            "progress":0.75
        }
    };
    var items=[]
    for(var key in temp_skills) {
        
        var value=temp_skills[key];
        
        const item=<Skill skill_name={key} skill_level={value['level']} progress={value['progress']}/>
        items.push(item)
    };
    console.log(items)
    return (
        <div className="skills">
        <h1>Skills</h1>
        <div className="skills-list">
            {items.map(item => (
                <div>{item}</div>
            ))}
            </div>
        </div>
    );
}

export default SkillsList